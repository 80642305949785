import { CAlert, CButton } from '@coreui/react';
import { useSelector } from 'react-redux';
import loadingImage from '../../assets/images/loading.gif';
import styles from './loading.module.scss';

const Loading = ({ loading = true, error = null, refresh = () => { }, fullscreen = false }) => {

  const sidebarShow = useSelector(state => state.panel.sidebarShow);
  const unfoldable = useSelector(state => state.panel.sidebarUnfoldable);

  const marginLeft = () => {
    if (fullscreen) {
      return '0px'
    } else {
      if (!sidebarShow) {
        return '0px';
      } else {
        if (unfoldable) {
          return '32px';
        } else {
          return '128px';
        }
      }
    }
  }

  return (
    <>
      {
        loading &&
        <div className={styles.loading_container} style={{ marginLeft: marginLeft() }}>
          <img src={loadingImage} />
        </div>
      }
      {
        error &&
        <CAlert className="nma-alert" color="light" variant="solid">
          {error}
          <CButton color="danger" style={{ marginLeft: 10 }} size="sm" onClick={() => refresh()}>Refresh</CButton>
        </CAlert>
      }
    </>
  );
}

export default Loading;
