import moment from 'moment';

export function currentDatetime() {
  return moment().format('YYYY-MM-DD HH:mm');
}

export function formatDatetime(value) {
  return moment(value).format('YYYY-MM-DD HH:mm');
}

export function formatDate(value) {
  return moment(value).format('YYYY-MM-DD');
}

export function getDomain() {
  if (window.location.port.length > 0) {
    return window.location.hostname;
  }
  return `${window.location.hostname.match(/\w*\.\w*$/gi)[0]}`;
}

export function isAbsoluteURL(url) {
  return url.startsWith("https://") || url.startsWith("http://")
}
