import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import panel from './panel/panel.reducer';
import user from './user/user.reducer';

const rootReducer = combineReducers({
  panel,
  user
});

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

export default store;
