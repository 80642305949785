import {userConstants} from "./user.constants";

const initialState = null

const user = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case userConstants.SET_CONFIG: {
      const updated = { ...state, ...rest };
      return updated;
    }
    case userConstants.READ_ONE_MESSAGE: {
      const unread_count = state.unread_notifications_count - 1;
      const updated = { ...state, unread_notifications_count: (unread_count >= 0 ? unread_count : 0) }
      return updated;
    }
    case userConstants.READ_ALL_MESSAGE: {
      return {...state, unread_notifications_count: 0};
    }
    case userConstants.REMOVE_CONFIG: {
      const updated = null;
      return updated;
    }
    default: {
      return state
    }
  }
}

export default user;
