import React from 'react'
import { CFooter } from '@coreui/react'

const AppFooter = () => {
  return (
    <CFooter>
      <div className="ms-auto">
        <span className="me-1"></span>
        <span className="ms-1 small">&copy; 2021-{(new Date()).getFullYear()} <a href="https://thenewmediaart.com">New Media Art Group</a></span>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter);
