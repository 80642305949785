import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CBadge,
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavItem,
  CNavLink,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilEnvelopeOpen, cilMenu } from '@coreui/icons'
import { AppBreadcrumb, AppHeaderDropdown } from '../index'
import { updateSidebarShow } from 'src/store/panel/panel.actions'
import { history } from 'src/helpers/history'

const AppHeader = () => {

  const dispatch = useDispatch()
  const sidebarShow = useSelector(state => state.panel.sidebarShow)
  const user = useSelector(state => state.user);

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch(updateSidebarShow(!sidebarShow))}>
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" style={{ cursor: 'pointer' }} onClick={() => { history.push("/") }} >
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav>
          <CNavItem>
            <CNavLink className="position-relative" style={{ cursor: 'pointer' }} onClick={() => { history.push("/messages") }}>
              <CIcon icon={cilEnvelopeOpen} size="lg" />
              {
                user.unread_notifications_count > 0 &&
                <CBadge size="sm" color="success" className="top-0 end-0 position-absolute" shape="rounded-pill">
                  {user.unread_notifications_count}
                </CBadge>
              }
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
        <small>Hi {user.name}</small>
      </CContainer>
    </CHeader>
  )
}

export default AppHeader;
