import React from 'react'
import { useLocation } from 'react-router-dom'
import routes from '../../routes'
import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react'
import { history } from 'src/helpers/history'

const AppBreadcrumb = () => {

  const currentLocation = useLocation().pathname;

  const getRouteName = (pathname, routes) => {
    const currentRoute = routes.find((route) => route.path === pathname)
    return currentRoute?.name
  }

  const getBreadcrumbs = (location) => {
    const breadcrumbs = []
    location.split('/').reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`;
      const name = getRouteName(currentPathname, routes);
      if (name) {
        breadcrumbs.push({
          pathname: currentPathname,
          name: name,
          active: index + 1 === array.length ? true : false,
        });
      }
      return currentPathname
    })
    return breadcrumbs
  }

  const breadcrumbs = getBreadcrumbs(currentLocation)

  return (
    <CBreadcrumb className="m-0 ms-2">
      <CBreadcrumbItem onClick={() => { history.push("/") }} style={{ cursor: 'pointer' }}>Home</CBreadcrumbItem>
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <CBreadcrumbItem
            {...(breadcrumb.active ? { active: true } : { onClick: () => { history.push(breadcrumb.pathname) } })}
            style={{ cursor: breadcrumb.active ? 'inherit' : 'pointer' }}
            key={index}
          >
            {breadcrumb.name}
          </CBreadcrumbItem>
        )
      })}
    </CBreadcrumb>
  )
}

export default React.memo(AppBreadcrumb)
