import { panelConstants } from "./panel.constants";

export const updateSidebarShow = value => {
  return {
    type: panelConstants.SET_CONFIG,
    sidebarShow: value
  };
};

export const updateSidebarUnfoldable = value => {
  return {
    type: panelConstants.SET_CONFIG,
    sidebarUnfoldable: value
  };
};
