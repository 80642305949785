import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { AppSidebarNav } from './AppSidebarNav'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import navigation from '../../_nav'
import { nmaIcon } from 'src/helpers/icons'
import { updateSidebarShow, updateSidebarUnfoldable } from 'src/store/panel/panel.actions'

const AppSidebar = () => {

  const dispatch = useDispatch();
  const unfoldable = useSelector(state => state.panel.sidebarUnfoldable);
  const sidebarShow = useSelector(state => state.panel.sidebarShow);

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={visible => {
        dispatch(updateSidebarShow(visible))
      }}>
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <CIcon icon={nmaIcon} height={28} />
        <div className="sidebar-brand-full nma-brand space-left">
          New Media Art
        </div>
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar style={{maxHeight: '100%'}}>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-md-flex"
        onClick={() => dispatch(updateSidebarUnfoldable(!unfoldable))}
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar);
