import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useToken from 'src/store/useToken';

const PrivateRoute = ({ component: Component, ...rest }) => {

  const { token } = useToken();

  return (
    <Route {...rest} render={props => (
      token
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', from: props.location }} />
    )} />
  );
};

export default PrivateRoute;
