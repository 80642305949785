import React, { Component } from 'react'
import { Route, Switch, Router } from 'react-router-dom'
import { Loading, PrivateRoute } from './components'
import { history } from './helpers/history'
import './scss/style.scss'


// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/auth/Login'))
const Logout = React.lazy(() => import('./views/auth/Logout'))
const Register = React.lazy(() => import('./views/auth/Register'))
const ForgetPassword = React.lazy(() => import('./views/auth/ForgetPassword'))
const Page404 = React.lazy(() => import('./views/pages/Page404'))
const Page500 = React.lazy(() => import('./views/pages/Page500'))

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <React.Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route exact path="/register" name="Register Page" render={(props) => <Register {...props} />} />
            <Route exact path="/forget-password" name="Forget Password Page" render={(props) => <ForgetPassword {...props} />} />
            <Route exact path="/logout" name="Logout Page" render={props => <Logout {...props} />} />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <PrivateRoute path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </React.Suspense>
      </Router>
    )
  }
}

export default App
