import { useState } from 'react';
import Cookies from 'js-cookie';
import { getDomain } from 'src/helpers/utilities';

export default function useToken() {

  const getToken = () => {
    return Cookies.get('token');
  };

  const setToken = token => {
    Cookies.set('token', token, { domain: getDomain() });
    saveToken(token);
  };

  const removeToken = () => {
    Cookies.remove("token", { domain: getDomain() });
  };

  const [token, saveToken] = useState(getToken);

  return { token, setToken, removeToken };
}
