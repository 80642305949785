import {
  cilBullhorn,
  cilHistory,
  cilImage,
  cilLink,
  cilSpeedometer,
  cilUser
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CNavItem, CNavTitle } from '@coreui/react'
import { nmaIcon } from './helpers/icons'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />
  },
  {
    component: CNavTitle,
    name: 'Account',
  },
  {
    component: CNavItem,
    name: 'Profile',
    to: '/profile',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'NMA  Credit',
    to: '/nma-credit',
    icon: <CIcon icon={nmaIcon} customClassName="nav-icon" />,
    disabled: true
  },
  {
    component: CNavTitle,
    name: 'Open Calls',
  },
  {
    component: CNavItem,
    name: 'Available Open Calls',
    to: '/open-calls',
    icon: <CIcon icon={cilBullhorn} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Open Calls History',
    to: '/open-calls-history',
    icon: <CIcon icon={cilHistory} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'A/V Collab',
    badge: {
      color: 'primary',
      text: 'Coming Soon',
    },
  },
  {
    component: CNavItem,
    name: 'A/V Collaboration',
    to: '/collaboration',
    icon: <CIcon icon={cilLink} customClassName="nav-icon" />,
    disabled: true
  },
  {
    component: CNavTitle,
    name: 'NFT',
    badge: {
      color: 'primary',
      text: 'Coming Soon',
    },
  },
  {
    component: CNavItem,
    name: 'NMA Collections',
    to: '/nma-collections',
    icon: <CIcon icon={cilImage} customClassName="nav-icon" />,
    disabled: true
  },
]

export default _nav
