import React from 'react';

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
const Settings = React.lazy(() => import('./views/settings/Settings'));
const Messages = React.lazy(() => import('./views/messages/Messages'));
const Profile = React.lazy(() => import('./views/profile/Profile'));
const EditProfile = React.lazy(() => import('./views/profile/EditProfile'));
const EditArtist = React.lazy(() => import('./views/profile/EditArtist'));
const OpenCalls = React.lazy(() => import('./views/opencalls/OpenCalls'));
const OpenCallsHistory = React.lazy(() => import('./views/opencalls/OpenCallsHistory'));

//Open Calls
const NeoShibuya15 = React.lazy(() => import('./views/opencalls/static/NeoShibuya15'));
const NeoShibuya30 = React.lazy(() => import('./views/opencalls/static/NeoShibuya30'));
const Channel24 = React.lazy(() => import('./views/opencalls/static/Channel24'));
const AVCollab = React.lazy(() => import('./views/opencalls/static/AVCollab'));

const routes = [
  { path: '/', name: 'Home', exact: true },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, exact: true },
  { path: '/settings', name: 'Settings', component: Settings, exact: true },
  { path: '/messages', name: 'Messages', component: Messages, exact: true },
  { path: '/profile', name: 'Profile', component: Profile, exact: true },
  { path: '/profile/edit-profile', name: 'Edit Profile', component: EditProfile, exact: true },
  { path: '/profile/edit-artist', name: 'Edit Artist', component: EditArtist, exact: true },
  { path: '/open-calls', name: 'Open Calls', component: OpenCalls, exact: true },
  { path: '/open-calls/shibuya15/:requestId?', name: 'Neo Shibuya 15', component: NeoShibuya15, exact: true },
  { path: '/open-calls/shibuya30/:requestId?', name: 'Neo Shibuya 30', component: NeoShibuya30, exact: true },
  { path: '/open-calls/24hchannel/:requestId?', name: '24h Channel', component: Channel24, exact: true },
  { path: '/open-calls/avcollab/:requestId?', name: 'AV Collab', component: AVCollab, exact: true },
  { path: '/open-calls-history', name: 'Open Calls History', component: OpenCallsHistory, exact: true },
]

export default routes
